// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  version: '1.3.0',
  domain: 'https://patient.sympliact.com',
  environment: 'production',
  sentry: {
    dns: 'https://d7becbb22f854f6cb767bea3ca101107@o1130118.ingest.sentry.io/6174278',
    traceSampleRate: 0.1
  },
  auth0: {
    client_id: '32rUQK5LKrpbJOFNQBwpKupyGN8RcdEW',
    domain: 'sympliact-pat-prod.auth0.com',
    redirect_uri: 'https://patient.sympliact.com/callback',
    logout_url: 'https://patient.sympliact.com/auth/login',
    audience: 'https://sympliact-pat-prod.auth0.com/api/v2/',
    scope: 'openid'
  },
  domainUrl: 'https://api-heroku-production.uc.r.appspot.com',
  baseUrl: 'https://api-heroku-production.uc.r.appspot.com/api/v1/pat'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
